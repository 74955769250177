
    import { defineComponent, ref, watch, onMounted,reactive } from 'vue';
    import { ElConfigProvider } from 'element-plus';
    import ptBr from 'element-plus/lib/locale/lang/pt-br';
    import moment from 'moment';
    import useEmitter from '@/composables/Emmiter'
    import AuthService from "@/services/AuthService";
    import { useStore } from 'vuex';
    import { Actions } from "@/store/enums/StoreEnums";
    import FilterBase from '@/layout/header/partials/filters/FilterBase.vue';
    import { codgruposelect } from '@/services/Concessionaria'

    export default defineComponent({
        name: "filtro-finalizados",
        components: {
            FilterBase,
            ElConfigProvider,
        },
        setup(props, { emit }) {
            const store = useStore();
            const dataInicialPeriodo = moment().startOf("month").toISOString();
            const dataFInalPeriodo = moment().endOf("day").toISOString();
            const valorInicialData = [dataInicialPeriodo, dataFInalPeriodo];
            let valorInicialCodConcessionaria;
            if(window.localStorage.getItem("id_usuario")){
                valorInicialCodConcessionaria = window.localStorage.getItem("filtroConcessionaria") ?? "";
            } else {
                valorInicialCodConcessionaria = window.sessionStorage.getItem("filtroConcessionaria") ?? "";
            }
            const codConcessionariaSelected:any = ref(valorInicialCodConcessionaria ?? "");
          
            // const datePicker = ref(valorInicialData);
            const userInfo = AuthService.getUsuarioAuth();
            const token = userInfo.token;
            const emitter = useEmitter();
            const error = ref(false);
            const activeModal = ref(false);
            const closeModal = ref(false);
            const disabledClick = ref(false);
            const lastEmitIsNull = ref(false);
            const modalFiltro:any = ref(true);
            const showClose = ref(false)
            const optionsConcessionaria:any= ref([]);
            const consultorSelected:any= ref("");
            // const typeFilterIsPeriodo:any= ref(['periodo']);
            const optionsConsultor:any= ref([]);
            const placa:any=ref("");
            const label = ref("Faça filtros por concessionária e data");
            const showGoToBack = ref(false);
            const loading:any= ref({
                concessionaria: false,
                filtrosConsultor: false,
            });
            const datePicker = reactive({
                ano: undefined,
                mes: undefined,
            })
           

            store.watch(() => store.getters.concessionariasList, () => {
                trataConcessionarias();
                console.log(store.getters.concessionariasList);
            }, { deep: true });

            onMounted(()=>{
                if(store.getters.concessionariasList.length > 0){
                    trataConcessionarias();
                    codGrupoFilter()
                 }
                 
            });

            async function resetDatePicker() {
                datePicker.ano = undefined
                datePicker.mes = undefined
                loading.value.filtrosConsultor = false;
            };

            async function codGrupoFilter(){
                const codGrupo = await codgruposelect()
                console.log(codGrupo)
            }
           
            async function trataConcessionarias() {
                const concessionarias = store.getters.concessionariasList;
                optionsConcessionaria.value = concessionarias.map(concessionaria => {
                    return {
                        text: concessionaria.nomeFantasia,
                        value: concessionaria.codConcessionaria,
                    }
                }).sort((concessionariaA, concessionariaB) => {
                    if (!concessionariaA.disabled && concessionariaB.disabled) {
                        return -1;
                    }
                    if (!concessionariaB.disabled && concessionariaA.disabled) {
                        return 1;
                    }
                    if (concessionariaA.disabled && !concessionariaA.disabled) {
                        if (concessionariaA.text < concessionariaB.text){
                            return -1;
                        }
                        if (concessionariaA.text > concessionariaB.text){
                            return 1;
                        }
                        return 0;
                    }
                    return 0;
                });
              
            };            
           
          
            const formateDate = () => {
                const moment = require('moment');

                let dates = {
                    ano: undefined,
                    mes: undefined
                }

                if(datePicker.ano){
                    const anoValue = moment(datePicker.ano);
                    dates.ano = anoValue.year(); 
                }

                if(datePicker.mes){
                    const mesValue = moment(datePicker.mes);
                    dates.mes =  mesValue.format('MM');
                }

                return dates              
             }

            async function emiteMudanca(){

                window.localStorage.setItem("filtroConcessionaria", codConcessionariaSelected.value)
                store.dispatch(Actions.SET_CONCESSIONARIA_SELECTED, codConcessionariaSelected.value);

                emitter.emit('filter-lancamento-mensal', {
                    codConcessionaria : codConcessionariaSelected.value,
                    datePicker : formateDate()
                })

                closeModal.value = !closeModal.value;
            };
            return {
                activeModal,
                closeModal,
                showClose,
                modalFiltro,
                loading, 
                ptBr, 
                datePicker, 
                emitter, 
                emiteMudanca, 
                valorInicialData, 
                lastEmitIsNull, 
                optionsConcessionaria,
                codConcessionariaSelected,
                optionsConsultor,
                consultorSelected,
                resetDatePicker,
                placa,
                error,
                label,
                showGoToBack,
                disabledClick
            };
        }
    });

import { CategoriaOS, Codgrupo, CodMarca, codUsuario, ConcessionariaModel, ModeloOS , Checklist, BuscarDms, GetCon } from "@/models/ConcessionariaModel";
import ApiService from "./ApiService";

/**
 * @description lista os itens reprovados da oficina digital
 */
 export const getIndex = async () => {
    const { data } = await ApiService.get(`/concessionarialist`);
    return data;
 };

export const indexPaginacao = async ( postData: any): Promise<Array<ConcessionariaModel>> => {
   const { data } = await ApiService.post(`/concessionarialistpaginacao`, postData);
   return data;
};

export const codgruposelect = async () : Promise<Codgrupo[]> => {
   const { data } = await ApiService.get(`/codgruposelect`);
   return data;
};

export const checklistselect = async (postData) : Promise<Checklist[]>  =>{
       const { data } = await ApiService.post(`/checklistSelect`, postData);
       return data;
    };

export const consultorSelect = async () : Promise<codUsuario[]> => {
   const { data } = await ApiService.get(`/consultorSelect`);
   return data;
};

export const marcaselect = async () : Promise<CodMarca[]> => {
   const { data } = await ApiService.get(`/marcaselect`);
   return data;
};

export const getDelet = async (codConcessionaria) => {
   const { data } = await ApiService.post(`/concessionariadelete/${codConcessionaria}`, {});
   return data;
};

export const getconcessionariaedit = async (codConcessionaria, postData) => {
   const { data } = await ApiService.post(`/concessionariaedit/${codConcessionaria}`, postData);
   return data;
};

export const getView = async (codConcessionaria) => {
   const { data } = await ApiService.get(`/concessionariaview/${codConcessionaria}`);
   return data;
};

export const getStore = async (postData) => {
   const { data } = await ApiService.post(`/concessionariaadd`, postData);
   return data;
};

export const getcidadeestado = async (postData,codConcessionaria) => {
   const { data } = await ApiService.post(`/cidadeestado/${codConcessionaria}`, postData);
   return data;
};

export const getmodeloOS = async () : Promise<ModeloOS[]> => {
   const { data } = await ApiService.get(`/modeloOS`);
   return data;
};

export const getDms = async () : Promise<BuscarDms[]> => {
   const { data } = await ApiService.get(`/dms`);
   return data;
};

export const estado = async () => {
   const { data } = await ApiService.get(`/estado`);
   return data;
};

export const configApp = async (payload) => {
   const { data } = await ApiService.put(`/configAppp/update`,payload);
   return data;
};

export const atualizarDadosApp = async (codConcessionaria: number) : Promise<void> => {
   const { data } = await ApiService.get(`/atualizarDadosApp/${codConcessionaria}`);
   return data;
};

interface PayloadCategoriaOs {
   dms: number,
   cnpj: string,
   con: string
}

export const categoriaOs = async (payload : PayloadCategoriaOs) : Promise<CategoriaOS> => {
   const { data } = await ApiService.post(`/categoriaOs`,payload as object);
   return data;
};

export const checkConnection = async (con : {con:string}) : Promise<GetCon> => {
   const { data } = await ApiService.post(`/verificarCon`,con as object);
   return data;
};

export default { 
   getcidadeestado, 
   getIndex, 
   getDelet, 
   getView, 
   indexPaginacao, 
   getStore,
   codgruposelect, 
   consultorSelect, 
   marcaselect, 
   getconcessionariaedit, 
   checklistselect, 
   getmodeloOS, 
   estado,
   categoriaOs,
   checkConnection
};